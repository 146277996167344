<template>
    <div class="carriage-route">
        <h2>Маршрут</h2>
        <div :id="id ? id : false">
            <h3 class="clr-ltg-3">Загрузка</h3>
            <component :is="(!$$.isArray(route.loading) || dataRoute.loading.length === 1) ? 'ul' : 'ol'">
                <li v-for="(v, i) in dataRoute.loading" :key="i">
                    <p v-if="v.region">{{ v.loadingPoint }}</p>
                    <p v-if="v.point" class="clr-ltg-2">
                        <small>{{ v.point }}</small>
                    </p>
                    <p><small class="clr-ltg-3">{{ $util.date.arrivingDate(v) }}</small></p>
                </li>
            </component>
            <h3 class="clr-ltg-3">Разгрузка</h3>
            <component :is="(!$$.isArray(route.loading) || dataRoute.unloading.length === 1) ? 'ul' : 'ol'">
                <li v-for="(v, i) in dataRoute.unloading" :key="i">
                    <p v-if="v.region">{{ v.unloadingPoint }}</p>
                    <p v-if="v.point" class="clr-ltg-2">
                        <small>{{ v.point }}</small>
                    </p>
                    <p><small class="clr-ltg-3">{{ $util.date.arrivingDate(v) }}</small></p>
                </li>
            </component>

        </div>
    </div>
</template>

<script>

export default {
    name: "CarriageRoute",
    props: {
        id: {
            type: [String, Number],
            default: "",
            required: false,
        },
        route: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            dataRoute: _.cloneDeep(this.route)
        }
    },
    created() {
        if (!_.isArray(this.route.loading)) {
            this.dataRoute.loading = [this.route.loading];
            this.dataRoute.unloading = [this.route.unloading];
        }
    },
    mounted() {
        this.$emit('init', this)
    },
    methods: {
        getAll() {
            return this.dataRoute.loading.concat(this.dataRoute.unloading)
        },
        getPoints() {
            const output = []
            const points = this.getAll()
            points.forEach(item => output.push(`${item.region}, ${item.city.name}, ${item.point}`))
            return output
        }
    }
}

</script>

<style lang="scss" scoped>
.carriage-route {

    > h2, h3 {
        font-family: $font-proxima-bold;
        font-size: 1.0em;
        margin-top: 0;
    }

    > h3 {
        margin-top: 15px;
        padding-top: 15px;
    }

    ul {
        margin: 0;
        padding-left: 0;
    }

    ul, ol {
        li {
            padding-bottom: 20px;

            p {
                margin: 5px 0 !important;
            }
        }
    }
}
</style>
