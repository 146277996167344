import AppEnv from './env'
import helper from '@/helpers'

export const CLIENT_HEADER = 'X-Rosprom-Client'
export const CLIENT_VERSION = 'WEB 2.10'
export const CONFIRMATION_MODAL_NAME = 'confirm'

export const DATABASE = {
    name: 'storage',
    tables: ['cities', 'images'],
}

const YANDEX = {
    map: {
        endpoint: 'https://api-maps.yandex.ru/2.1',
        params: {
            apikey: 'ecc3df87-b367-4151-b368-5aa01aabe682',
            lang: 'ru_RU',
            coordorder: 'latlong',
        }
    }
}

const CONFIG = {

    version: CLIENT_VERSION,

    endpoint: 'https://gw.rsprm.ru/v1/',

    gateway: 'https://gw.rsprm.ru/1cbackend/rb/hs/Gateway',

    stomp: 'wss://gw.rsprm.ru/ws-api',

    yandex: {...YANDEX},

    yandexMapSetup: () => helper.$util.page
        .loadScript(_.uriEncode(CONFIG.yandex.map.endpoint, CONFIG.yandex.map.params))
        .catch(() => helper.$notify().alert('Не удалось загрузить Яндекс Карты.')),

    isLocalhost: () => window.location.hostname.match(/(localhost|127\.0\.0\.1)/) !== null,
    isTest: () => false,
    isDev: () => process?.env?.NODE_ENV === 'development',

    // isProd: () => (AppEnv.endpoint && !AppEnv.endpoint.match(/stage/g)) || _.mxUrlParam('_prod') !== null,

    mask: {
        phone: "+7 (###) ###-##-##",
    },

    sessionRefreshPeriod: 60 * 24 * 1000,

    expire: {
        auth: (60 * 60 * 24) * 1000,
        regions: (60 * 60 * 24) * 1000,
        transport_types: (60 * 60) * 1000,
        feeding_types: (60 * 60) * 1000, //Список способов погрузки/разгрузки
        loading_methods: (60 * 60) * 1000, //Список способов погрузки
        carriage_types: (60 * 60) * 1000, //Список способов перевозки
        order_status_types: (60 * 60) * 1000, //Список статусов заявки заказа
        repair_order_statuses: (60 * 60) * 1000, //Список статусов заявки на ремонт СТ
        reserved_bid_notification: (60 * 60 * 24) * 1000, //Уведомление о резервной ставке в тендерах
    },

    roles: {
        CARRIER: 'Перевозчик',
        DISPATCHER: 'Диспетчер',
        DRIVER: 'Водитель',
    },

}

export default Object.assign(CONFIG, AppEnv)
