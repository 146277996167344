<template>
  <header>
    <section class="section-container" v-click-outside="() => hideProfile(true)">
      <section class="section-header">
        <div class="section-header-left">
          <img src="~@/assets/img/logo.png" alt="" class="logo" @click="$link('/')">
        </div>
        <nav class="nav-menu">
          <BaseLayoutNav />
        </nav>
        <div class="section-header-right">
          <div class="messages-icon" @click="showMessages">
            <img src="~@/assets/icons/messages.png" alt="Messages" />
            <div v-if="messagesCount > 0" class="message-count">{{ messagesCount }}</div>
          </div>
          <div class="profile-icon" @mouseover="showProfile" @touchstart="showProfile">
            <img src="~@/assets/icons/profile.png" alt="Profile" />
            <span class="profile-name">
              <template v-if="isAuthorized">
                <router-link v-if="roleName && !$service.account.access.isDispatcher()" :to="`/profile/${roleName}`" class="link link-black">
                  {{ $service.account.shortName() }}
                </router-link>
                <template v-else>
                  {{ $service.account.shortName() }}
                </template>
              </template>
              <template v-else>
                <router-link :to="{name: 'Login'}">Вход</router-link>
              </template>
            </span>
          </div>
          <div class="after-element"></div> <!-- Элемент after -->
        </div>
      </section>
    </section>

    <MessageConfirm @setup="i => messageConfirmation = i" />
    <Popup v-if="messageConfirmation" name="messages" class="section-header-messages">
      <h3 slot="header"><strong>Сообщения</strong></h3>
      <template v-if="isAuthorized">
        <Messages ref="messages" :messageConfirm="messageConfirmation" @unread-quantity="q => messagesCount = q" @chat="id => chat(id)" />
      </template>
      <template v-else>
        <div class="tx-c mt-20">Вход не выполнен</div>
      </template>
    </Popup>
    <Popup name="base_manager_chat">
      <h3 slot="header" class="tx-c">
        <FaIcon icon="comments" class="clr-ltg-2"/>
        Чат
      </h3>
      <ManagerChat :key="chatId" :chat-id="chatId" />
    </Popup>
  </header>
</template>

<script>
import Messages from '@pages/Messages.vue'
import MessageConfirm from '@pages/MessageConfirm.vue'
import ManagerChat from "@pages/chats/ManagerChat";
import BaseLayoutNav from './BaseLayoutNav.vue';

export default {
  name: "HeaderNav",
  components: {
    ManagerChat,
    Messages,
    MessageConfirm,
    BaseLayoutNav
  },
  computed: {
    isAuthorized() {
      return this.$service.account.isAuthorized()
    },
    roleName() {
      return this.$service.account.access.getRole()?.toLowerCase()
    }
  },
  data: () => ({
    messagesCount: 0,
    chatId: 0,
    showProfileInfo: false,
    profileHovered: false,
    messageConfirmation: null
  }),
  created() {
    this.isAuthorized && this.getUnreadMessages()
  },
  methods: {
    getUnreadMessages() {
      this.$rs.bg().repo('notify').getUnreadQuantity().finally(({object}) => {
        this.messagesCount = !object ? 0 : +object
      })
    },
    showMessages() {
            const icon = this.$refs.messagesIcon
            this.$popup('messages', {
                footer: false,
                clickToClose: false,
                style: () => {
                    const w = 600
                    const h = 495
                    const {left, top} = icon.getBoundingClientRect()
                    const x = left + icon.offsetWidth - w
                    const y = top + icon.offsetHeight

                    return {
                        width: w + 'px',
                        height: h + 'px',
                        left: x + 'px',
                        top: y + 'px'
                    }
                }
            })
    },
    showProfile() {
      this.showProfileInfo = true
    },
    hideProfile(force) {
            this.profileHovered = false
            if (force === true) return this.showProfileInfo = false
            setTimeout(() => this.profileHovered || (this.showProfileInfo = false), 3000)
    },
    chat(id) {
            this.chatId = id
            this.$popup('base_manager_chat', {
                clickToClose: false,
                footer: false,
                onClose: () => {
                    this.chatId = null
                }
            })
    }
  }
}
</script>

<style scoped lang="scss">
header {
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: $blue; // Замените на ваш цвет, аналогичный nav-menu
  }

  .section-header-right {
    display: flex;
    align-items: center;
    margin-left: auto; // Убедитесь, что элементы выровнены справа
    position: relative; // Для позиционирования элемента after
  }

  .messages-icon {
    position: relative;
    margin-right: 20px; // Отступ между иконками
    cursor: pointer;
  }

  .message-count {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red; // Цвет фона для уведомления
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 12px;
  }

  .profile-icon {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 20px; // Отступ между иконками
  }

  .profile-name {
    margin-left: 5px; // Отступ между иконкой и именем
  }

  .after-element {
    content: "";
    position: absolute;
    top: 50%;
    right: 0; // Позиционирование элемента after
    transform: translateY(-50%); // Центрируем по вертикали
    width: 100%; // Ширина элемента after, чтобы он занимал всю высоту
    height: 100%; // Высота элемента after
    background: url(~@/assets/icons/arrow-down.png) no-repeat center center;
    z-index: -1; // Позади других элементов
  }
}
</style>
