export default {
    assign: {
        transportTypeId: ({set, value, vm}) => {
            vm.$repo('transport').transportTypes().then(({list}) => set(_.find(list, 'id', value, 'name'), 'prompt'))
        },
        carriageTypeId: ({set, value, vm}) => {
            vm.$repo('transport').carriageTypes().then(({list}) => set(_.find(list, 'id', value, 'name'), 'prompt'))
        },
        feedingTypeId: ({set, value, vm}) => {
            vm.$repo('transport').feedingTypes().then(({list}) => set(_.find(list, 'id', value, 'name'), 'prompt'))
        },
    },
    model: {
        "cargoDescription": "",
        "transportTypeId": 0,
        "cargoWeight": "",
        "bunks": "",
        "refrigeratorTemperature": "",
        "thermograph": "",
        "securitySeals": "",
        "removableStanchions": "",
        "sanitization": "",
        "pricePerKm": 0,
        "note": "",
        "minInternalDimensions": {
            "height": 0,
            "length": 0,
            "width": 0,
        },
        "minHeight": 0,
        "minBelting": "",
        "medicalCard": "",
        "hardCrates": "",
        "pallettNumber": 0,
        "carriageTypeId": 0,
        "carriageType": "",
        "feedingTypeId": 0,
        "transportNumber": 0,
    },
    fields: {
        "cargoDescription": {
            name: "Характеристики груза",
            if: ({value}) => value,
        },
        "transportTypeId": {
            name: "Тип транспорта",
            if: ({value}) => value,
            prompt: '-'
        },
        "cargoWeight": {
            name: "Вес груза",
            if: ({value}) => value,
        },
        "bunks": {
            name: "Коники",
            if: ({value}) => value,
            prompt: 'Да'
        },
        "refrigeratorTemperature": {
            name: "Режим рефрижератора",
            if: ({value}) => value,
        },
        "thermograph": {
            name: "Термописец",
            if: ({value}) => value,
            prompt: 'Да'
        },
        "securitySeals": {
            name: "Пломбировочный тросс",
            if: ({value}) => value,
            prompt: 'Да'
        },
        "removableStanchions": {
            name: "Съемные стойки",
            if: ({value}) => value,
            prompt: 'Да'
        },
        "sanitization": {
            name: "Санитарная обработка",
            if: ({value}) => value,
            prompt: 'Да'
        },
        "pricePerKm": {
            name: "Цена за километр",
            if: ({value}) => parseFloat(value)
        },
        "note": {
            name: "Примечание",
            if: ({value}) => value,
        },
        "minInternalDimensions.height": {
            name: "Высота",
            if: ({value}) => value,
        },
        "minInternalDimensions.length": {
            name: "Длина",
            if: ({value}) => value,
        },
        "minInternalDimensions.width": {
            name: "Ширина",
            if: ({value}) => value,
        },
        "minHeight": {
            name: "Высота не менее",
            if: ({value}) => value,
        },
        "minBelting": {
            name: "Ремни, не менее",
            if: ({value}) => value,
        },
        "medicalCard": {
            name: "Медицинская книжка",
            if: ({value}) => value,
            prompt: 'Да'
        },
        "hardCrates": {
            name: "Жесткая обрешетка",
            if: ({value}) => value,
            prompt: 'Да'
        },
        "pallettNumber": {
            name: "Количество паллет",
            if: ({value}) => value,
        },
        "carriageTypeId": {
            name: "Способ перевозки",
            if: ({value}) => value,
            prompt: '-'
        },
        "feedingTypeId": {
            name: "Способ загрузки",
            if: ({value}) => value,
            prompt: '-'
        },
        "transportNumber": {
            name: "Количество машин",
            if: ({value}) => value,
        },
        "sanTreat": {
            name: "Санитарная обработка",
            if: ({value}) => value,
            prompt: 'Да'
        },	
    }
}
