<template>
    <section>
        <Header v-if="$route.name !== 'Privacy'"/>
        <section v-if="status" class="section-container section-page-container">
            <PageNotFound v-if="status === 404"/>
        </section>
        <section v-else class="section-container section-page-container">
            <router-view :key="$route.path"/>
        </section>
        <Footer v-if="$route.name !== 'Privacy'"/>
    </section>
</template>

<script>
import {PUBLIC_ROUTES} from '@/router/routes'

import Header from './BaseLayoutHeader.vue'
import Footer from './BaseLayoutFooter.vue'

import PageNotFound from '@pages/PageNotFound.vue'

export default {
    name: "BaseLayout",
    components: {Header, Footer, PageNotFound},
    props: {
        status: Number
    },
    beforeRouteUpdate (to, from, next) {
        setTimeout(() => this.fixOverflow(), 3000)
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (PUBLIC_ROUTES.includes(vm.$route?.name)) return
            if (false === vm.$service.account.isAuthorized()) {
                vm.$redirect('/')
            }
        })
    },
    methods: {
        fixOverflow () {
            if ($('.modal-in').length) return
            document.body.style.overflow = null
        }
    }
}
</script>

<style lang="scss">
.section-container {
    width: $container-width+px;
    max-width: $container-width+px;
    margin: 0 auto;
    @media (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
    }
}

.section-page-container {
    min-height: 557px;
    @media (max-width: 425px) {
        min-height: 320px;
    }
}

.section-page {
    margin-bottom: 40px;
	width: 101.7%;
    &-title {
        font-family: $font-proxima-extra-bold;
        font-size: 19pt;
        font-weight: 600;
        padding-left: 14px;
        margin-top: 22px;
        letter-spacing: 1px;
    }
	.tx-c {
		margin-top: 65px;
	}
	.card-preorder-container {
		margin-top: 40px;
	}
}

</style>
