var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[(_vm.preorder)?_c('FormPreOrder',{attrs:{"preorder":_vm.preorder,"isTaxOn":_vm.setFilterRate},on:{"toggle":function($event){return _vm.redir()},"amount-changed":_vm.onAmountChanged}}):_vm._e(),(_vm.getMode())?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preorder),expression:"!preorder"}]},[_c('h1',{staticClass:"section-page-title"},[_vm._v(" Найти груз ")]),(_vm.filter && _vm.regions && _vm.cities && _vm.transportTypes && _vm.loadingMethods)?_c('PreOrderFilter',_vm._b({key:'filters-' + _vm.load,on:{"reset":_vm.resetFilter,"apply":_vm.applyFilter,"setRate":_vm.setRate}},'PreOrderFilter',{
                    filter: _vm.filter,
                    filterRate: _vm.filterRate,
                    setFilterRate: _vm.rateName || null,
                    regions: _vm.regions,
					cities: _vm.cities,
                    transportTypes: _vm.transportTypes,
                    loadingMethods: _vm.loadingMethods,
                    filterCargoWeightMax: _vm.filterCargoWeightMax,
                    filterCargoWeightMin: _vm.filterCargoWeightMin,
                    changeFilterWeightRange: _vm.changeFilterWeightRange
                },false),[(!_vm.$$.isEmpty(_vm.filterSaved) && _vm.pageCount > 0)?_c('p',{staticClass:"preorder-filter-count",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Найдено "+_vm._s(_vm.pageCount >= _vm.pageSize ? ("более " + _vm.pageSize) : _vm.pageCount)+" грузов ")]):_vm._e()]):_vm._e(),_c('DataTable',{key:_vm.load,ref:"table",staticClass:"table-row",attrs:{"cols":{
                    number: 'Номер',
                    endDateTime: 'Время до окончания',
                    route: 'Маршрут',
                    transportTypeId: 'Прицеп',
                    bidAmount: 'Ставка',
                    reserveBidAmount: 'Резерв',
                    rate: function () { return _vm.rateName ? _vm.filterRate[_vm.rateName] : 'Тариф'; },
                    totalSum: 'Итого',
                    // type: '',
                },"cell-bind":{
                    number: {class: 'tx-inline'},
                    route: {style: {maxWidth: '400px'}},
                    rate: {class: 'tx-inline', title: 'Тариф'},
                    totalSum: {class: 'tx-inline', title: 'Сумма тарифа (деньгами + ГСМ)'},
                    bidAmount: {class: 'tx-inline', title: 'Ставка'},
                    reserveBidAmount: {class: 'tx-inline', title: 'Резервная ставка'}
                },"col-data":{
                    rate: {bind: {class: 'tx-inline'}}
                },"cells":{
                     number: function (v) { return ({'div.tx-bold': v, bind: {style: {color: '#545454'}}}); },
                     transportTypeId: function (v) { return _vm.$$.find(_vm.transportTypes, 'id', v, 'name'); },
                     // type: (v, item) => ({
                     //    'div.preorder-type': item.byFixedBid ? 'Фикс' : 'Тендер',
                     //    bind: {class: item.byFixedBid && '--fixed'}
                     // }),
                     endDateTime: function (v, item) { return item.byFixedBid === true || ({
                        Countdown: '',
                        bind: {
                            date: _vm.$util.date.dateFromMsk(v),
                            showSeconds: false,
							viewTime: true,
                            shorten: true,
                            roundDays: true,
                            expiredText: 'Завершен'
                        }
                     }); },
                     route: function (v, item) { return [
                        {html: "<small class='clr-ltg-2'>" + (_vm.$util.date.arrivingDate(item.loading)) + "</small>"
                        + "<p class='mt-5'>" + (item.loading.region) + " " + (item.loading.city.name) + "</p>"},
                        {html: "<small class='clr-ltg-2'>" + (_vm.$util.date.arrivingDate(item.unloading)) + "</small>"
                        + "<p class='mt-5 mb-0'>" + (item.unloading.region) + " " + (item.unloading.city.name) + "</p>"} ]; },
                     bidAmount: function (v, item) { return _vm.$$.price(item.byFixedBid ? item.fixedBidAmount : v, '-'); },
                     reserveBidAmount: function (v) { return _vm.$$.price(v, '-'); },
                     rate: function (v, item) { return _vm.getRate(item); },
                     totalSum: function (v, item) { return _vm.getRate(item, true); }
                },"fetch":_vm.fetchData,"paginate":true,"pageSize":_vm.pageSize,"assign":_vm.getAssign,"click":function (preorder) { return _vm.getPreorder(preorder.id); }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }