<template>
    <div class="form-row form-filter preorder-filter">
        <div class="form-row-header mb-20">
            <slot name="header"/>
            <button class="btn btn-white btn-small mr-15 ml-15" @click.prevent.self="$emit('reset')">Сбросить</button>
            <button class="btn btn-dark-blue btn-small" @click.self.prevent="$emit('apply')">Применить</button>
        </div>
        <div class="form-filter-wrap">
            <Form class="form-row-inner">
                <div class="form-row-inner-row">
                    <FormItem label="Откуда" style="width: 28%">
                        <FormFindSelect
                            v-model="filter.loadingRegion"
                            objectsArray
                            :options="locationOptions"
                            label="id"
                            labelName="name"
                        />
                    </FormItem>
                    <FormItem label="Куда" style="width: 28%">
                        <FormFindSelect
                            v-model="filter.unloadingRegion"
                            objectsArray
                            :options="locationOptions"
                            label="id"
                            labelName="name"
                        />
                    </FormItem>
					<FormItem label="Дата загрузки" row style="min-width: 20%;">
						<date-picker v-model="filter.startLoadDate" placeholder="с" format="DD-MM-YYYY" value-type="YYYY-MM-DD" type="date" ></date-picker>
						<date-picker v-model="filter.endLoadDate" placeholder="по" format="DD-MM-YYYY" value-type="YYYY-MM-DD" type="date" :disabled-date="disabledAfterToday"></date-picker>

                    </FormItem>
                </div>

                <div class="form-row-inner-row">
				
                    <!--                    <FormItem label="Тип заявки" style="min-width: 22%;">
                                            <FormSelect
                                                v-model="filter.preorderType"
                                                :options="{
                                                    ALL: 'Любой',
                                                    AUCTION: 'Тендер',
                                                    FIX_BID: 'Фиксированная ставка',
                                                }"
                                            />
                                        </FormItem>-->
                    <FormItem label="Тип транспорта" style="min-width: 28%; max-width: 20%">
                        <FormMultiSelect
                            v-if="transportTypes"
                            v-model="filter.transportTypeId"
                            :options="transportTypes"
                        />
                    </FormItem>
                    <FormItem label="Тоннаж" noShadow style="min-width: 18%">
                        <div>
                            <span>От {{ filter.cargoWeightMin }} тонн</span>
                            <template v-if="filter.cargoWeightMax < filterCargoWeightMax">
                                <span> До {{ filter.cargoWeightMax }} </span>
                                <span>тонн</span>
                            </template>
                            <span v-else-if="filter.cargoWeightMax === filterCargoWeightMax"> До неограничено</span>
                        </div>
                        <div>
                            <RangeDualSlider
                                :min="filterCargoWeightMin"
                                :max="filterCargoWeightMax"
                                :min-threshold="filterCargoWeightMin"
                                @change="changeFilterWeightRange"
                            />
                        </div>
                    </FormItem>

                    <FormItem label="Без НДС / С НДС" style="width: 8%">
                        <label class="switch">
                            <input type="checkbox" :checked="checkboxStatus" @click="checkBoxFunck">
                            <div class="slider round"></div>
                        </label>
                    </FormItem>

                    <FormItem label="Способ загрузки" style="min-width: 19%; max-width: 20%">
                        <FormMultiSelect
                            v-if="loadingMethods"
                            v-model="filter.loadingMethodsId"
                            :options="loadingMethods"
                        />
                    </FormItem>
					<FormItem label="Статус" style="min-width: 20%; max-width: 20%">
                        <FormSelect
                            v-model="filter.tenderStatus"
                            :options="{
                                ALL: 'Все тендеры',
                                ACTIVE: 'Действующий',
                                COMPLETED: 'Завершенный',
                            }"
                        />
                    </FormItem>
                </div>
                <!--                <div v-if="['FIX_BID', 'AUCTION'].includes(filter.preorderType)" class="form-row-inner-row">
                                    <FormItem v-if="filter.preorderType === 'FIX_BID'" label="Сортировка по фиксированной ставке" style="width: 30%">
                                        <FormSelect
                                            v-model="filter.sortFixedBidAmount"
                                            :options="{
                                                ASC: 'По возрастанию',
                                                DESC: 'По убыванию',
                                            }"
                                        />
                                    </FormItem>
                                    <FormItem v-if="filter.preorderType === 'AUCTION'" label="Статус" style="min-width: 20%">
                                        <FormSelect
                                            v-model="filter.tenderStatus"
                                            :options="{
                                                ALL: 'Все тендеры',
                                                ACTIVE: 'Действующий',
                                                COMPLETED: 'Завершенный',
                                            }"
                                        />
                                    </FormItem>
                                </div>-->

            </Form>
        </div>
    </div>
</template>

<script>
import RangeDualSlider from '@plugins/RangeDualSlider.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru';

export default {
    name: "FormPreOrderFilter",
    props: {
        filter: Object,
        filterRate: Object,
        setFilterRate: String,
        regions: Array,
		cities: Array,
        transportTypes: Array,
        loadingMethods: Array,
        filterCargoWeightMax: Number,
        filterCargoWeightMin: Number,
        changeFilterWeightRange: Function,
    },
    components: {
		RangeDualSlider,
		DatePicker,
	},
	data () {
		return {
			checkboxStatus: true,
			}
		},
	created () {
		console.log(this.regions)
		console.log(this.cities)
		if (this.$repo('carrier').companyDetails.formOfPayment==="Без НДС"){
			this.$emit('setRate', 'withoutTax')
			this.checkboxStatus==false
			this.$emit('setCheckboxVal', this.checkboxStatus)
		}
		else {
			this.$emit('setRate', 'withTax')
			this.checkboxStatus==true
			this.$emit('setCheckboxVal', this.checkboxStatus)
		}
	},
	methods: {
		checkBoxFunck() {
			if (this.checkboxStatus){
				this.$emit('setRate', 'withoutTax')
			}
			else {
				this.$emit('setRate', 'withTax')
			}
			this.checkboxStatus = !this.checkboxStatus
			this.$emit('setCheckboxVal', this.checkboxStatus)
		},
		disabledAfterToday(date) {
			const today = new Date(this.filter.startLoadDate);
			today.setHours(0, 0, 0, 0);
			return date < today
    },
	},
	computed: {
    locationOptions() {
		return [...this.regions, ...this.cities];
    }
  },
}
</script>

<style lang="scss" scoped>
.preorder-filter {
    &-count {
        margin: 4px 14px;
        padding: 0;
        font-size: 12px;
		font-family: sans-serif;
        color: $blue;
    }
	.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #101010;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
