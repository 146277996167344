<template>
    <header>
        <section class="section-container" v-click-outside="() => hideProfile(true)">
            <section class="section-header">
                <div class="section-header-left">
                    <img src="~@/assets/img/logo.png" alt="" class="logo" @click="$link('/')">
                </div>
				<NavBar v-if="$route.name !== 'Privacy'"/>
                <div class="section-header-right">
                    <div class="--messages">
                        <div ref="messagesIcon" class="--icon" @click="showMessages">
                            <div v-show="messagesCount > 0"
                                 :key="messagesCount"
                                 class="--count"
                                 :style="messagesCount < 10 ? {padding: '5px 8px'} : false"
                            >
                                {{ messagesCount }}
                            </div>
                        </div>
                    </div>
                    <div class="--profile-icon" @mouseover="showProfile" @touchstart="showProfile"></div>
                    <div class="--profile-name" @mouseover="showProfile" @touchstart="showProfile">
                        <template v-if="isAuthorized">
                            <router-link
                                v-if="roleName && !$service.account.access.isDispatcher()"
                                :to="`/profile/${roleName}`"
                                class="link link-black"
                            >
                                {{ $service.account.shortName() }}
                            </router-link>
                            <template v-else>
                                {{ $service.account.shortName() }}
                            </template>
                        </template>
                        <template v-else>
                            <router-link :to="{name: 'Login'}">Вход</router-link>
                        </template>
                    </div>
                </div>
                <div v-if="isAuthorized"
                     v-show="showProfileInfo"
                     class="--profile-info"
                     @mouseout="hideProfile"
                     @mouseover="profileHovered = true"
                >
                    <div>
                        <div>
                            <div>
                                <p class="fs-11 tx-bold">{{ $service.account.fullName() }}</p>
                                <p class="fs-10 clr-ltg">
                                    {{ $service.account.get('phone') | mask('phone') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div><img src="~@/assets/icons/settings.png" alt=""></div>
                            <div class="tx-lh-0_5">
                                <p>
                                    <router-link class="link link-black" :to="{path: '/settings'}">Настройки
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div><img src="~@/assets/icons/logout.png" alt=""></div>
                            <div class="tx-lh-0_5">
                                <p><a href="" class="link link-black" @click="$service.account.logout()">Выйти</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <MessageConfirm @setup="i => messageConfirmation = i"  />

        <Popup v-if="messageConfirmation" name="messages" class="section-header-messages">
            <h3 slot="header"><strong>Сообщения</strong></h3>
            <template v-if="isAuthorized">
                <Messages
                    ref="messages"
                    :messageConfirm="messageConfirmation"
                    @unread-quantity="q => messagesCount = q"
                    @chat="id => chat(id)"
                />
            </template>
            <template v-else>
                <div class="tx-c mt-20">Вход не выполнен</div>
            </template>
        </Popup>
        <Popup name="base_manager_chat">
            <h3 slot="header" class="tx-c">
                <FaIcon icon="comments" class="clr-ltg-2"/>
                Чат
            </h3>
            <ManagerChat :key="chatId" :chat-id="chatId" />
        </Popup>
    </header>
</template>

<script>

import Messages from '@pages/Messages.vue'
import MessageConfirm from '@pages/MessageConfirm.vue'
import ManagerChat from "@pages/chats/ManagerChat";
import NavBar from './BaseLayoutNav.vue';

export default {
    name: "BaseLayoutHeader",
    components: {
        ManagerChat,
        Messages,
        MessageConfirm,
		NavBar
    },
    computed: {
        isAuthorized() {
            return this.$service.account.isAuthorized()
        },
        roleName() {
            return this.$service.account.access.getRole()?.toLowerCase()
        }
    },
    data: () => ({
        messagesCount: 0,
        chatId: 0,
        showProfileInfo: false,
        profileHovered: false,
        messageConfirmation: null
    }),
    beforeRouteUpdate(to, from, next) {
        this.isAuthorized && this.getUnreadMessages()
        next()
    },
    created() {
        this.isAuthorized && this.getUnreadMessages()
    },
    methods: {
        getUnreadMessages() {
            this.$rs.bg().repo('notify').getUnreadQuantity().finally(({object}) => {
                this.messagesCount = !object ? 0 : +object
            })
        },
        showMessages() {
            const icon = this.$refs.messagesIcon
            this.$popup('messages', {
                footer: false,
                clickToClose: false,
                style: () => {
                    const w = 600
                    const h = 495
                    const {left, top} = icon.getBoundingClientRect()
                    const x = left + icon.offsetWidth - w
                    const y = top + icon.offsetHeight

                    return {
                        width: w + 'px',
                        height: h + 'px',
                        left: x + 'px',
                        top: y + 'px'
                    }
                }
            })
        },
        showProfile() {
            this.showProfileInfo = true
        },
        hideProfile(force) {
            this.profileHovered = false
            if (force === true) return this.showProfileInfo = false
            setTimeout(() => this.profileHovered || (this.showProfileInfo = false), 3000)
        },
        chat(id) {
            this.chatId = id
            this.$popup('base_manager_chat', {
                clickToClose: false,
                footer: false,
                onClose: () => {
                    this.chatId = null
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
header {
    .section-header {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		justify-content: center;
        position: relative;
        height: $header-height+px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
		align-content: center;

        .--profile-info {
            position: absolute;
            right: 0;
            top: 75%;
            z-index: 10;
            background-color: #fff;
            width: 200px;
            min-height: 245px;
            box-shadow: $box-shadow-thin-drop;

            > div {
                border-bottom: $gray-e7 1px solid;
                padding: 10px 0;

                &:last-child {
                    border-bottom: none;
                }

                > div {
                    padding: 0 12px;
                    display: flex;
                    align-items: stretch;
                    justify-content: left;

                    > div {
                        > p {
                            margin: 14px 0;
                        }

                        &:nth-child(1) {
                            width: 22%;
                            padding-left: 14px;
                            padding-top: 9px;
                        }
                    }
                }

                &:first-child {
                    > div > div {
                        padding: 0;

                        &:nth-child(1) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &-messages {
            position: absolute;

            /deep/ > .modal > .modal-header {
                background-color: $blue-light-extra;
                border-radius: 5px 5px 0 0;
                border-bottom: none;
                padding-left: 35px;

                &-close {
                    right: 8px;
                    top: 5px;
                    font-size: 23pt;
                    color: $black-33;
                }

                > h3 {
                    font-size: 14pt;
                }
            }

            /deep/ > .modal > .modal-content {
                > div {
                    padding: 0;
                }
            }
        }

        &-left {
            display: flex;
            align-items: center;
            align-self: flex-start;
            width: $header-top-left-width+px;
            height: inherit;
            .logo {
                cursor: pointer;
                width: 250px;
            }

            @media (max-width: 425px) {
                width: 50%;
                padding-left: 2%;
                .logo {
                    width: 100% !important;
                }
            }
        }

        &-right {
            display: flex;
            align-items: center;
            align-self: flex-end;
            width: $header-top-right-width+px;
            height: inherit;
            padding-top: 2px;
            position: relative;
            @media (max-width: 1024px) {
                width: auto;
                padding-right: 20px;
                &:after {
                    left: 88% !important;
                }
            }

            .--messages {
                .--icon {
                    position: relative;
                    margin-left: 14px;
                    background: url(~@/assets/icons/messages.png) no-repeat center center;
                    border-radius: 50%;
                    padding: 18px 19px;
                    background-color: $blue-dark-light;
                    cursor: pointer;

                    .--count {
                        font-family: $font-roboto;
                        position: absolute;
                        border-radius: 50%;
                        background-color: $red;
                        padding: 5px;
                        top: -4px;
                        right: -13px;
                        color: #fff;
                        font-size: 8pt;
                    }
                }
            }

            .--profile-icon {
                position: relative;
                margin-left: 22px;
                background: url(~@/assets/icons/profile.png) no-repeat center center;
                border-radius: 50%;
                padding: 18px 19px;
                cursor: pointer;
                background-color: $blue-light-extra;
            }

            .--profile-name {
                padding-left: 12px;
                padding-right: 15px;
                word-wrap: break-word;
                font-size: 12pt;
                letter-spacing: 1px;
                font-weight: 600;
                line-height: 19px;
                max-width: 125px;
                margin-top: 3px;
                @media (max-width: 768px) {
                    display: none;
                }
            }

            &:after {
                content: "";
                background: url(~@/assets/icons/arrow-down.png) no-repeat center center;
                position: absolute;
                width: 12px;
                height: 8px;
                background-size: 9px 9px;
                top: 47.5%;
                left: 92%;
            }
        }
    }
}
</style>
